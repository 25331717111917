import { css } from '@bts-web/utils-style-engine';

export const SkeletonElement = ({
  className = '',
  width = '100%',
  height = '40px',
  borderRadius = '0px',
  testId,
  maxWidth,
  minWidth,
  backgroundColor = 'neutrals.fill_focused',
  ...rest
}: {
  className?: string;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  height?: string;
  borderRadius?: string;
  testId?: string;
  backgroundColor?: string;
  [key: string]: any;
}) => (
  <div
    className={`${css({
      width: width,
      height: height,
      background: backgroundColor,
      borderRadius: borderRadius,
      maxWidth,
      minWidth,
    })} ${className}`}
    data-testid={testId || 'skeleton-element'}
    {...rest}
  ></div>
);
