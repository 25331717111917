import { stack, hstack, css } from '@bts-web/utils-style-engine';
import { TransactionItemSkeleton } from '../transactions/TransactionItem/TransactionItemSkeleton';
import { AreaChart, PageSkeletonWrapper, SkeletonElement } from '../common';

export const PortfolioLoadingSkeleton = () => (
  <PageSkeletonWrapper>
    <PortfolioContentLoadingSkeleton />

    <LatestTransactionsSkeleton />
  </PageSkeletonWrapper>
);

export const PortfolioContentLoadingSkeleton = () => (
  <>
    <PortfolioHeaderSkeleton />

    <AreaChart locale="de-DE" seriesData={[]} />

    <PortfolioOverviewSkeleton />
  </>
);

export const LatestTransactionsSkeleton = () => (
  <>
    <TransactionItemSkeleton />
    <TransactionItemSkeleton />
    <TransactionItemSkeleton />
  </>
);

export const PortfolioHeaderSkeleton = () => (
  <div
    className={stack({
      gap: 'extra_small_2',
      paddingX: 'medium',
      paddingY: 'small',
      width: '100%',
    })}
    data-testid="portfolio-header-skeleton-container"
  >
    <SkeletonElement
      width="62px"
      height="16px"
      borderRadius="30px"
      testId="portfolio-header-skeleton-title"
    />

    <div
      className={css({
        display: 'flex',
        gap: 'extra_small_2',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media (min-width: 1024px)': {
          justifyContent: 'start',
          gap: 'medium',
        },
      })}
    >
      <div className={css({ hideFrom: 'lg' })}>
        <SkeletonElement
          width="231px"
          height="32px"
          borderRadius="16px"
          testId="portfolio-header-skeleton-left"
        />
      </div>

      <div className={css({ hideBelow: 'lg' })}>
        <SkeletonElement
          width="231px"
          height="46px"
          borderRadius="16px"
          testId="portfolio-header-skeleton-left"
        />
      </div>

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'extra_small_2',
          alignItems: 'end',
          hideFrom: 'lg', // mobile
        })}
        data-testid="portfolio-header-skeleton-right"
      >
        <SkeletonElement width="70px" height="16px" borderRadius="30px" />

        <SkeletonElement width="52px" height="18px" borderRadius="30px" />
      </div>

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'extra_small_2',
          alignItems: 'end',
          hideBelow: 'lg', // desktop
        })}
        data-testid="portfolio-header-skeleton-right"
      >
        <SkeletonElement width="70px" height="16px" borderRadius="30px" />

        <SkeletonElement width="68px" height="27px" borderRadius="32px" />
      </div>
    </div>
  </div>
);

export const PortfolioOverviewSkeleton = () => (
  <div
    className={stack({
      gap: 'small',
      paddingX: 'medium',
      paddingY: 'large',
      width: '100%',
    })}
    data-testid="portfolio-overview-skeleton-container"
  >
    <SkeletonElement
      width="193px"
      height="32px"
      borderRadius="16px"
      testId="portfolio-overview-skeleton-title"
    />
    <div className={hstack({ justifyContent: 'space-between' })}>
      <div
        className={stack({ gap: 'small' })}
        data-testid="portfolio-overview-skeleton-left"
      >
        <SkeletonElement width="62px" height="14px" borderRadius="30px" />
        <SkeletonElement width="104px" height="14px" borderRadius="30px" />
      </div>
      <div
        className={stack({ gap: 'small' })}
        data-testid="portfolio-overview-skeleton-right"
      >
        <div className={hstack()}>
          <SkeletonElement width="62px" height="14px" borderRadius="30px" />
          <SkeletonElement width="52px" height="14px" borderRadius="30px" />
        </div>
        <div className={hstack()}>
          <SkeletonElement width="62px" height="14px" borderRadius="30px" />
          <SkeletonElement width="52px" height="14px" borderRadius="30px" />
        </div>
      </div>
    </div>
  </div>
);
