'use client';

import { TransactionDirection } from '@bts-web/data-layer/server';
import {
  TransactionCell,
  type TransactionCellProps,
} from '@bts-web/design-system/component/transaction-cell';
import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';
import { TransactionTypes } from '../types/transactions';
import { getTransactionIconName } from '../utils/transactions';

export type TransactionCellElemProps = {
  visual?: 'default' | 'success' | 'warning' | 'error';
  displaySecondIconSlot?: boolean;
  transactionType: TransactionTypes;
  direction?: TransactionDirection;
} & Omit<
  TransactionCellProps['external'],
  'firstIconSlot' | 'secondIconSlot'
> & {
    transactionType: TransactionTypes;
    direction?: TransactionDirection;
    displaySecondIconSlot?: boolean;
  } & React.HTMLAttributes<HTMLDivElement>;

const ThemedTransactionCell = ({
  visual = 'default',
  transactionType,
  displaySecondIconSlot = true,
  assetAmountText,
  date,
  fiatAmountText,
  title,
  type,
  transactionStatus,
  transactionBadgesSlot,
  disabled,
  onClick,
  tagContent,
  id,
}: TransactionCellElemProps) => (
  <TransactionCell
    assetAmountText={assetAmountText}
    date={date}
    fiatAmountText={fiatAmountText}
    title={title}
    disabled={disabled}
    onClick={onClick}
    tagContent={tagContent}
    transactionBadgesSlot={transactionBadgesSlot}
    transactionStatus={transactionStatus}
    type={type}
    aria-label={`${title} ${type} ${id}`}
    className={css({
      display: 'flex',
      flexDirection: 'column',
      fontSize: 'body.medium',
      color: 'neutrals.text_secondary',
      borderBottomWidth: '1px',
      borderColor: 'neutrals.divider',
      backgroundColor: 'transparent',
      py: 'small',
      ...(!displaySecondIconSlot ? { paddingRight: '28px' } : {}),
      '&[data-state="active"]': {
        _hover: {
          cursor: 'pointer',
        },
        _active: {
          backgroundColor: 'neutrals.fill_focused',
        },
      },
      '& [data-element="details"]': {
        display: 'flex',
        gap: 'small',
        justifyContent: 'space-between',
      },
      '& [data-element="title"]': {
        fontSize: 'label.medium_medium',
        color: 'neutrals.text_primary',
      },
      '& [data-element="first-icon-slot"]': {
        color: 'neutrals.text_primary',
        display: 'flex',
        alignItems: 'center',
      },
      '& [data-element="details-first-half"]': {
        display: 'flex',
        gap: 'small',
        minWidth: 0,
        '& div:nth-child(2)': {
          display: 'flex',
          flexDirection: 'column',
          gap: 'extra_small_2',
          minWidth: 0,
          '& > span': {
            wordBreak: 'normal',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
        },
      },
      '& [data-element="details-second-half"]': {
        display: 'flex',
        gap: 'extra_small_2',
        '& div:first-child': {
          display: 'flex',
          flexDirection: 'column',
          gap: 'extra_small_2',
          justifyContent: 'center',
          alignItems: 'end',
          '& > span': {
            whiteSpace: 'nowrap',
          },
        },
      },
      '& [data-element="second-icon-slot"]': {
        display: 'flex',
        marginInlineStart: 'auto',
        alignItems: 'center',
        minWidth: '20px',
      },
      '& [data-element="badges"]': {
        paddingInlineStart: 'extra_large',
      },
    })}
    moneyAmountClassName={css({
      fontSize: 'label.medium_medium',
      color: 'neutrals.text_primary',
      ...(visual === 'default' || !visual
        ? { color: 'neutrals.text_primary' }
        : {}),
      ...(visual === 'success' ? { color: 'positive.text_primary' } : {}),
      ...(visual === 'warning' ? { color: 'neutrals.text_primary' } : {}),
      ...(visual === 'error' ? { color: 'neutrals.text_disabled' } : {}),
    })}
    statusClassName={css({
      ...(visual === 'default' || !visual
        ? { color: 'neutrals.text_primary' }
        : {}),
      ...(visual === 'success' ? { color: 'positive.text_primary' } : {}),
      ...(visual === 'warning' ? { color: 'warning.text_primary' } : {}),
      ...(visual === 'error' ? { color: 'negative.text_primary' } : {}),
    })}
    firstIconSlot={<Icon icon={getTransactionIconName({ transactionType })} />}
    secondIconSlot={
      displaySecondIconSlot ? <Icon icon="arrow-right-gray" /> : null
    }
  />
);

export { ThemedTransactionCell as TransactionCell };
