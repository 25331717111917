import { css } from '@bts-web/utils-style-engine';
import { SkeletonElement } from '../loading/SkeletonElement/SkeletonElement';
import { HiddenMobile } from '../../../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenMobile/HiddenMobile';

export const DocumentItemSkeleton = () => (
  <div
    data-testid="doc-item-skeleton"
    className={css({
      display: 'flex',
      gap: 'medium',
      alignItems: 'center',
      width: '100%',
      paddingX: 'medium',
      paddingY: 'extra_large_2',
      borderBottomWidth: '1px',
      borderColor: 'neutrals.divider',
      lg: {
        paddingY: 'medium',
      },
    })}
  >
    <HiddenMobile>
      <SkeletonElement width="22px" height="22px" borderRadius="50%" />
    </HiddenMobile>
    <div
      className={css({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        gap: 'small',
      })}
    >
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'extra_small_3',
          flex: 1,
        })}
      >
        <SkeletonElement width="250px" height="20px" />
        <SkeletonElement width="150px" height="16px" />
      </div>
      <SkeletonElement width="16px" height="16px" borderRadius="50%" />
    </div>
  </div>
);
