import { TFunction } from 'i18next';
import { UserTopBarMenuTranslations } from '../DesktopTopHeaderBar/subcomponents/UserTopBarMenu/UserTopBarMenu';
import { TopBarLanguageTranslations } from '../DesktopTopHeaderBar/subcomponents/TopbarLanguageMenuWrapper/TopbarLanguageMenuWrapper';

export type IMainNavsTranslations = UserTopBarMenuTranslations &
  TopBarLanguageTranslations & {
    profile: string;
    legalNotice: string;
    reportsAndStatements: string;
    discoverText: string;
    portfolioText: string;
    buttonTransferTopNav: string;
    buySellButtonText: string;
    tradeText: string;
    readOnly: string;
    deposit: string;
    send: string;
    languageTitle: string;
    errorPendingApprovalTransfer: string;
    errorTitleTransfersUnavailable: string;
    goToPortfolio: string;
  };

const mainNavsTranslations: IMainNavsTranslations = {
  profile: 'general_profile',
  customerSupport: 'customer_support',
  frequentlyAskedQuestions: 'frequently_asked_questions',
  legalNotice: 'legal_notice',
  generalLogout: 'general_logout',
  termsAndConditions: 'terms_and_conditions',
  twoFaWelcomeTitle: '2fa_welcome_title',
  twoFaResetTitle: '2fa_reset_title',
  reportsAndStatements: 'reports_and_statements',
  legalDocumentation: 'legal_documentation',
  discoverText: 'section_discover',
  portfolioText: 'nav_bar_portfolio',
  buttonTransferTopNav: 'button_transfer_top_nav',
  buySellButtonText: 'button_trade_top_nav',
  tradeText: 'nav_bar_trade',
  readOnly: 'user_role_info_read_only',
  deposit: 'deposit',
  send: 'send',
  germanSubtitle: 'select_language_de',
  englishSubtitle: 'select_language_en',
  errorTitle: 'internal_error_headline',
  errorSubtitle: 'info_toast_asset_unavailable_subtitle',
  languageTitle: 'language',
  errorPendingApprovalTransfer: 'error_message_pending_approval_transfers',
  errorTitleTransfersUnavailable: 'error_title_transfers_unavailable',
  goToPortfolio: 'go_to_portfolio',
};

export const getMainNavsTranslations = (
  translationUtil: TFunction,
): IMainNavsTranslations => {
  return Object.entries(mainNavsTranslations).reduce((acc, [key, value]) => {
    acc[key as keyof IMainNavsTranslations] = translationUtil(value);

    return acc;
  }, {} as IMainNavsTranslations);
};
