'use client';

import { useClientTranslation } from '@bts-web/utils-lokalise';
import { TFunction } from 'i18next';
import {
  getTransactionTranslationKeywordByType,
  TransactionTypes,
} from '../types/transactions';
import { getTransactionTagByType } from './transactions';

export const useTransactionTitle = ({
  transactionType,
  name,
}: {
  transactionType: TransactionTypes;
  name?: string | null;
}) => {
  const { t } = useClientTranslation();

  const option = {
    0: name,
    interpolation: {
      escapeValue: false,
    },
  };

  const tagValue: string | null = getTransactionTagByType(transactionType);

  const translationKey =
    getTransactionTranslationKeywordByType(transactionType);

  return {
    title: (t as TFunction)(translationKey, option),
    grams: t('grams'),
    tagValue: tagValue ? (t as TFunction)(tagValue) : null,
  };
};
