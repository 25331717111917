'use client';

import { useState } from 'react';
import { Icon } from '@bts-web/design-system/component/icon';
import { ButtonBase } from '../../ButtonBase/ButtonBase';
import { useClientUserInfoUpdater } from '../../../userinfo/InitialUserInfoFetcherAndParser/utils/useClientUserInfo';

interface DownloadDocumentButtonProps {
  documentId: string;
  documentDownloadUrl: string | ((documentId: string) => Promise<string>);
  onDownloadCompleted: () => void;
}

const DownloadDocumentButton = ({
  documentDownloadUrl,
  onDownloadCompleted,
  documentId,
}: DownloadDocumentButtonProps) => {
  const { refreshUserInfo } = useClientUserInfoUpdater();

  const [isLoading, setIsLoading] = useState(false);

  const onClickDownloadButton = async () => {
    let downloadUrl = documentDownloadUrl;

    if (typeof documentDownloadUrl !== 'string') {
      downloadUrl = await documentDownloadUrl(documentId);
    }

    setIsLoading(true);

    const linkElement = document.createElement('a');

    linkElement.target = '_blank';

    linkElement['dataset'].testid = 'download-link';

    linkElement.href = downloadUrl as string;

    linkElement.rel = 'noopener noreferrer';

    linkElement.download = downloadUrl as string;

    document.body.appendChild(linkElement);

    // setTimeout is required for Safari mobile
    setTimeout(() => {
      linkElement.click();
    }, 0);

    document.body.removeChild(linkElement);

    refreshUserInfo();

    onDownloadCompleted();

    setIsLoading(false);
  };

  return (
    <ButtonBase
      onClick={onClickDownloadButton}
      disabled={isLoading}
      visual={'ghost'}
      size="small"
      noPadding
    >
      <Icon icon="arrow-download" theme="regular" size="16" />
    </ButtonBase>
  );
};

export { DownloadDocumentButton };
