import { css } from '@bts-web/utils-style-engine';
import { FC, HTMLAttributes } from 'react';

interface ContentSectionWrapperOwnProps {
  sectionTitle?: string;
  sectionDescription?: string | React.ReactNode;
  children: React.ReactNode;
}

type ContentSectionWrapperProps = ContentSectionWrapperOwnProps &
  Omit<HTMLAttributes<HTMLElement>, keyof ContentSectionWrapperOwnProps>;

const ContentSectionWrapper: FC<ContentSectionWrapperProps> = ({
  children,
  sectionTitle,
  sectionDescription,
  className,
  ...restProps
}) => {
  return (
    <section
      className={`${css({
        px: { base: 'small', md: 'medium' },
        pt: { base: 'medium' },
        '&:last-of-type': {
          pb: { base: 'medium' },
        },
      })} ${className ?? ''}`}
      {...restProps}
    >
      {sectionTitle || sectionDescription ? (
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: 'small',
            mb: { base: 'small', md: 'medium' },
          })}
        >
          {sectionTitle && (
            <h3
              className={css({
                fontSize: 'headline.small_medium',
                fontWeight: 'headline.small_medium',
                letterSpacing: 'headline.small_medium',
                lineHeight: 'headline.small_medium',
              })}
              data-testid="portfolio-latest-transactions"
              aria-label="portfolio latest transactions"
            >
              {sectionTitle}
            </h3>
          )}

          {typeof sectionDescription === 'string' ? (
            <span
              className={css({
                fontSize: 'body.medium',
                fontWeight: 'body.medium',
                letterSpacing: 'body.medium',
                lineHeight: 'body.medium',
                color: 'neutrals.text_primary',
              })}
            >
              {sectionDescription}
            </span>
          ) : sectionDescription ? (
            sectionDescription
          ) : null}
        </div>
      ) : null}

      {children}
    </section>
  );
};

export { ContentSectionWrapper };
