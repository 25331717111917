import {
  transactionTagTranslationsByLocalType,
  TransactionTypes,
} from '../types/transactions';

export const getTransactionIconName = ({
  transactionType,
}: {
  transactionType: TransactionTypes;
}): string => {
  if (transactionType === 'BUY') {
    return 'buy';
  }

  if (transactionType === 'SELL') {
    return 'sell';
  }

  if (
    transactionType === 'OTC' ||
    transactionType === 'DEDUCTION' ||
    transactionType === 'TENDER' ||
    transactionType === 'RIGHTS_ISSUE' ||
    transactionType === 'TRANSACTION'
  ) {
    return 'send-giveaway';
  }

  if (transactionType === 'GIVEAWAY' || transactionType === 'STAKING_REWARD') {
    return 'gift';
  }

  if (
    transactionType === 'MERGER_CASH' ||
    transactionType === 'MERGER_STOCK' ||
    transactionType === 'MERGER_CRYPTO'
  ) {
    return 'merger';
  }

  if (transactionType === 'SWAP') {
    return 'swap';
  }

  if (transactionType === 'SAVINGS_PLAN') {
    return 'piggy-bank';
  }

  if (transactionType === 'DELISTING' || transactionType === 'BASE_TIMELINE') {
    return 'delisting';
  }

  if (transactionType === 'DIVIDEND') {
    return 'divident';
  }

  if (transactionType === 'METAL_STORAGE_FEE') {
    return 'storage-lock';
  }

  if (transactionType === 'STOCK_SPLIT') {
    return 'split-forward';
  }

  if (transactionType === 'REVERSE_STOCK_SPLIT') {
    return 'split-backward';
  }

  if (transactionType === 'TRADE' || transactionType === 'SPINOFF') {
    return 'up-down-arrows';
  }

  if (transactionType === 'TAX_REFUND') {
    return 'receive-giveaway';
  }

  if (transactionType === 'WITHDRAWAL') {
    return 'withdraw';
  }

  if (transactionType === 'DEPOSIT') {
    return 'deposit';
  }

  return 'sell';
};

type TaggedTransactionTypes =
  keyof typeof transactionTagTranslationsByLocalType;

export const getTransactionTagByType = (
  transactionType: TransactionTypes,
): string | null => {
  return transactionType in transactionTagTranslationsByLocalType
    ? transactionTagTranslationsByLocalType[
        transactionType as TaggedTransactionTypes
      ]
    : null;
};
