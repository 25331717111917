'use client';

import { FC } from 'react';
import { getAppConfig } from '@bts-web/core-app-config';
import { type TopBarMenuItemProps } from '../TopBarMenuItem/TopBarMenuItem';
import { TopBarMenu } from '../TopBarMenu/TopBarMenu';
import { logoutAction } from '../../../../../auth/actions';
import {
  TriggerResetTwoFaModal,
  TriggerSetupTwoFaModal,
  useClientUserInfo,
} from '../../../../../common/';
import { useComposableModal } from '@bts-web/utils-context';
import { useDrawer } from '../../../../Drawer/DrawerContextProvider';

export type UserTopBarMenuTranslations = {
  frequentlyAskedQuestions: string;
  customerSupport: string;
  termsAndConditions: string;
  generalLogout: string;
  twoFaWelcomeTitle: string;
  twoFaResetTitle: string;
  reportsAndStatements: string;
  legalDocumentation: string;
};

interface ITopBarMenuProps {
  translatedValues: UserTopBarMenuTranslations;
  isWithinDropdown?: boolean;
}

export const UserTopBarMenu: FC<ITopBarMenuProps> = ({
  translatedValues,
  isWithinDropdown,
}) => {
  const { showModal } = useComposableModal();

  const { closeDrawer } = useDrawer();

  const {
    appName,
    localPublicFolderPathTo_FAQDocument,
    URLforCustomerSupport,
  } = getAppConfig();

  const { twoFactorAuthentication } = useClientUserInfo();

  const isTwoFaSetup = twoFactorAuthentication === 'ENABLED';

  const twoFaClickAction = () => {
    closeDrawer('mobile-profile-drawer');

    isTwoFaSetup
      ? showModal(TriggerResetTwoFaModal)
      : showModal(TriggerSetupTwoFaModal);
  };

  const TopBarMenuItems: TopBarMenuItemProps[] = [
    ...(appName === 'LBBW'
      ? [
          {
            icon: 'shield',
            iconTheme: 'regular',
            label: isTwoFaSetup
              ? translatedValues.twoFaResetTitle
              : translatedValues.twoFaWelcomeTitle,
            type: 'button',
            action: twoFaClickAction,
          } as TopBarMenuItemProps,
          {
            icon: 'question-circle',
            iconTheme: 'regular',
            label: translatedValues.frequentlyAskedQuestions,
            type: 'downloadLink',
            action: localPublicFolderPathTo_FAQDocument,
          } as TopBarMenuItemProps,
          {
            icon: 'call',
            label: translatedValues.customerSupport,
            type: 'externalLink',
            iconTheme: 'regular',
            action: URLforCustomerSupport,
          } as TopBarMenuItemProps,
        ]
      : []),
    {
      label: translatedValues.reportsAndStatements,
      type: 'link',
      icon: 'certificate',
      iconTheme: 'regular',
      action: '/reports-and-statements',
    },
    {
      label: translatedValues.legalDocumentation,
      type: 'link',
      icon: 'scales',
      iconTheme: 'regular',
      action: '/legal-documentation',
    },
    {
      label: translatedValues.generalLogout,
      type: 'button',
      icon: 'logout',
      action: async () => {
        await logoutAction();
      },
    },
  ];

  return (
    <TopBarMenu
      isWithinDropdown={isWithinDropdown}
      TopBarMenuItems={TopBarMenuItems}
    />
  );
};
