'use client';

import Image from 'next/image';
import { css } from '@bts-web/utils-style-engine';
import ArrowUp from './icons/arrow-up.svg';
import ArrowDown from './icons/arrow-down.svg';
import Equal from './icons/equal.svg';
import PinUp from './icons/pin-up.svg';
import PinDown from './icons/pin-down.svg';
import BadgeNeutral from './icons/badge-neutral.svg';

type PriceVariationProps = {
  trend: 'positive' | 'negative' | 'neutral';
  percentage?: string;
  value?: string;
  timestampText?: string;
  timestampPosition?: 'top' | 'right' | 'bottom';
  visual?:
    | 'filledPositive'
    | 'filledNegative'
    | 'filledNeutral'
    | 'outlinePositive'
    | 'outlineNegative'
    | 'outlineNeutral'
    | 'ghostPositive'
    | 'ghostNegative'
    | 'ghostNeutral'
    | 'iconStyleAccentPositive'
    | 'iconStyleAccentNegative'
    | 'iconStyleAccentNeutral';
  valuePosition?: 'left' | 'right' | 'bottom';
  size?: 'small' | 'medium' | 'large' | 'smallAccent' | 'mediumAccent';
};

const PriceVariationIcon = ({ trend }: { trend: string }) => {
  if (trend === 'positive')
    return <Image src={ArrowUp} width={18} height={18} alt="arrow up" />;

  if (trend === 'negative')
    return <Image src={ArrowDown} width={18} height={18} alt="arrow down" />;

  return <Image src={Equal} width={12} height={12} alt="equal" />;
};

const PercentageIndicator = ({ trend }: { trend: string }) => {
  if (trend === 'positive')
    return <Image src={PinUp} width={16} height={17} alt="pin up" />;

  if (trend === 'negative')
    return <Image src={PinDown} width={16} height={17} alt="pin down" />;

  return (
    <Image src={BadgeNeutral} width={16} height={17} alt="circle neutral" />
  );
};

export function PriceVariation({
  value,
  percentage,
  timestampText,
  trend,
  timestampPosition = 'right',
  valuePosition = 'right',
  visual = 'filledPositive',
  size = 'medium',
}: PriceVariationProps) {
  return (
    <div
      className={css({
        // root
        display: 'inline-flex',
        alignItems: 'end',
        gap: 'extra_small_3',
        ...(size === 'small' && {
          fontSize: 'caption.small',
          fontWeight: 'caption.small',
          lineHeight: 'caption.small',
        }),
        ...(size === 'smallAccent' && {
          fontSize: 'caption.small',
          fontWeight: 'caption.small',
          lineHeight: 'caption.small',
        }),
        ...(size === 'medium' && {
          fontSize: 'caption.medium_medium',
          fontWeight: 'caption.medium_medium',
          lineHeight: 'caption.medium_medium',
        }),
        ...(size === 'mediumAccent' && {
          fontSize: { base: 'body.medium_medium', lg: 'title.large' },
          fontWeight: { base: 'body.medium_medium', lg: 'title.large' },
          lineHeight: { base: 'body.medium_medium', lg: 'title.large' },
        }),
        ...(timestampPosition === 'right' && {
          alignItems: 'center',
        }),
        ...(timestampPosition === 'top' && {
          flexDirection: 'column-reverse',
          lg: {
            gap: 'extra_small_2',
          },
        }),

        ...(timestampPosition === 'bottom' && {
          flexDirection: 'column',
          lg: {
            gap: 'extra_small_2',
          },
        }),
      })}
      data-testid="price-variation"
      aria-label={`price variation ${value} in format ${valuePosition}`}
      data-state={trend}
    >
      <div
        // left
        className={css({
          display: 'flex',
          gap: 'extra_small_3',
          alignItems: 'center',
          ...(valuePosition === 'left' && { flexDirection: 'row-reverse' }),
          ...(valuePosition === 'bottom' && {
            flexDirection: 'column',
            alignItems: 'end',
          }),
        })}
      >
        <span
          className={css({
            // icon
            display: 'flex',
            width: '20px',
            height: '20px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            ...(visual === 'filledPositive' && { display: 'none' }),
            ...(visual === 'filledNegative' && { display: 'none' }),
            ...(visual === 'filledNeutral' && { display: 'none' }),

            ...(visual === 'outlinePositive' && { display: 'none' }),
            ...(visual === 'outlineNegative' && { display: 'none' }),
            ...(visual === 'outlineNeutral' && { display: 'none' }),

            ...(visual === 'ghostPositive' && { display: 'none' }),
            ...(visual === 'ghostNegative' && { display: 'none' }),
            ...(visual === 'ghostNeutral' && { display: 'none' }),

            ...(visual === 'iconStyleAccentPositive' && {
              backgroundColor: 'positive.fill_primary',
            }),
            ...(visual === 'iconStyleAccentNegative' && {
              backgroundColor: 'negative.fill_primary',
            }),
            ...(visual === 'iconStyleAccentNeutral' && {
              backgroundColor: 'neutrals.fill_primary',
            }),
          })}
        >
          <PriceVariationIcon trend={trend} />
        </span>
        {percentage && (
          // percentage
          <div
            className={css({
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '32px',
              px: 'extra_small_3',
              '& > span': {
                flexShrink: 0,
              },
              padding: {
                lg: 'extra_small_3',
              },
              ...(visual === 'filledNeutral' && {
                color: 'neutrals.text_primary',
                backgroundColor: 'neutrals.fill_focused',
              }),
              ...(visual === 'outlinePositive' && {
                borderWidth: '1px',
                borderColor: 'positive.fill_primary',
              }),
              ...(visual === 'outlineNegative' && {
                borderWidth: '1px',
                borderColor: 'negative.fill_primary',
              }),
              ...(visual === 'outlineNeutral' && {
                borderWidth: '1px',
                borderColor: 'neutrals.fill_secondary',
              }),
              ...(visual === 'filledPositive' && {
                color: 'positive.text_primary',
                backgroundColor: 'positive.fill_tertiary',
              }),
              ...(visual === 'filledNegative' && {
                color: 'negative.text_primary',
                backgroundColor: 'negative.fill_tertiary',
              }),
              ...(visual === 'ghostPositive' && {
                background: 'none',
                color: 'positive.text_primary',
              }),
              ...(visual === 'ghostNegative' && {
                background: 'none',
                color: 'negative.text_primary',
              }),
              ...(visual === 'ghostNeutral' && {
                background: 'none',
                color: 'neutrals.text_primary',
              }),

              ...(visual === 'iconStyleAccentPositive' && {
                display: 'none',
              }),
              ...(visual === 'iconStyleAccentNegative' && {
                display: 'none',
              }),
              ...(visual === 'iconStyleAccentNeutral' && {
                display: 'none',
              }),

              ...(size === 'small' && {
                height: '16px',
                p: 'extra_small_3',
              }),
              ...(size === 'smallAccent' && {
                height: '16px',
                p: 'extra_small_3',
              }),
              ...(size === 'medium' && {
                height: '25px',
                p: 'extra_small_3',
              }),
            })}
          >
            <span className="price-variation__percentage-indicator">
              <PercentageIndicator trend={trend} />
            </span>

            <span className="price-variation__percentage-text">
              {percentage}
            </span>
          </div>
        )}
        {/* // value */}
        {value && (
          <span
            className={css({
              color: 'neutrals.text_secondary',
              whiteSpace: 'nowrap',
              ...(visual === 'iconStyleAccentPositive' && {
                color: 'positive.text_primary',
              }),
              ...(visual === 'iconStyleAccentNegative' && {
                color: 'negative.text_primary',
              }),
              ...(visual === 'iconStyleAccentNeutral' && {
                color: 'neutrals.text_primary',
              }),

              ...(size === 'smallAccent' && {
                color: 'neutrals.text_primary',
                fontSize: 'label.medium_medium',
                fontWeight: 'label.medium_medium',
                lineHeight: 'label.medium_medium',
              }),
            })}
          >
            {value}
          </span>
        )}
      </div>

      {timestampText && (
        // right
        <div
          className={css({
            paddingInlineStart: 'extra_small_2',
            ...(visual === 'ghostPositive' && { display: 'none' }),
            ...(visual === 'ghostNegative' && { display: 'none' }),
            ...(visual === 'ghostNeutral' && { display: 'none' }),
          })}
        >
          {/* // timestamp */}
          <span
            className={css({
              color: 'neutrals.text_secondary',
              ...(size === 'mediumAccent' && {
                lg: {
                  fontSize: 'caption.medium_medium',
                  fontWeight: 'caption.medium_medium',
                  lineHeight: 'caption.medium_medium',
                },
              }),
            })}
          >
            {timestampText}
          </span>
        </div>
      )}
    </div>
  );
}

export default PriceVariation;
