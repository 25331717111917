'use client';

import { ButtonBase, ButtonComponentProps } from '../../common';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { useRouter } from 'next/navigation';
import { FC } from 'react';

const ErrorRetryButton: FC<Omit<ButtonComponentProps, 'children'>> = (
  props,
) => {
  const { t } = useClientTranslation();

  const router = useRouter();

  return (
    <ButtonBase onClick={router.refresh} {...props}>
      {t('inline_error_tranasaction_fail')}
    </ButtonBase>
  );
};

export { ErrorRetryButton };
