import {
  TransactionsWidgetController,
  TransactionsWidgetControllerProps,
} from './TransactionsWidgetController.server';
import { ErrorBoundaryWithSuspense } from '../../layouts/ErrorBoundary/ErrorBoundaryWithSuspense';
import { css } from '@bts-web/utils-style-engine';
import { SkeletonElement } from '../components';

const TransactionsWidgetControllerWithSuspense = async ({
  variant,
  showOnlyRedirectLink = false,
  fetchNumberOfItems = 20,
  assetId,
}: TransactionsWidgetControllerProps) => {
  return (
    <ErrorBoundaryWithSuspense
      fallbackOrSkeleton={
        <div className={css({ p: 'small' })}>
          <SkeletonElement height="60px" />
        </div>
      }
    >
      <TransactionsWidgetController
        fetchNumberOfItems={fetchNumberOfItems}
        variant={variant}
        assetId={assetId}
        showOnlyRedirectLink={showOnlyRedirectLink}
      />
    </ErrorBoundaryWithSuspense>
  );
};

export { TransactionsWidgetControllerWithSuspense };
