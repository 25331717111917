/**
 * @generated SignedSource<<c2fabbbadea7154f6a6725b88af2084d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NotificationType = "NEW_LEGAL_DOCUMENTS" | "REPORT";
export type NotificationsViewQuery$variables = {};
export type NotificationsViewQuery$data = {
  readonly notifications: ReadonlyArray<NotificationType | null>;
};
export type NotificationsViewQuery = {
  response: NotificationsViewQuery$data;
  variables: NotificationsViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "notifications",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsViewQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationsViewQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "205c9a4c481228b9dd2fc88ca9e4f181",
    "id": null,
    "metadata": {},
    "name": "NotificationsViewQuery",
    "operationKind": "query",
    "text": "query NotificationsViewQuery {\n  notifications\n}\n"
  }
};
})();

(node as any).hash = "6593430a977ce81aa6eaf2992ade7e22";

export default node;
