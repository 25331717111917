import {
  MaximumFractionDigits,
  intlAssetValueFormatting,
  intlFiatValueFormatting,
} from '@bts-web/utils-formatting';
import { type AmountFor } from '@bts-web/data-layer/server';
import { Locale } from '@bts-web/utils-lokalise';

export const getAmountInputPlaceHolder = ({
  locale,
  currency,
  displayType,
  symbol,
}: {
  locale: Locale;
  displayType: AmountFor;
  currency: string;
  symbol: string;
}): string => {
  if (displayType === 'FIAT') {
    return intlFiatValueFormatting(0.0, {
      locale,
      currency,
      minimumFractionDigits: MaximumFractionDigits.FIAT,
    });
  }

  const placeholderAssetValue = intlAssetValueFormatting(0.0, {
    locale,
    minimumFractionDigits: MaximumFractionDigits.ASSET,
    notation: 'standard',
  });

  return `${placeholderAssetValue} ${symbol}`;
};
