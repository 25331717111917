'use client';

import { useState } from 'react';
import { convertToLocaleDateString } from '@bts-web/utils-formatting';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { getLocale, i18n, Language } from '@bts-web/utils-lokalise';
import { Status } from '../Status/Status';
import { HiddenMobile } from '../../../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenMobile/HiddenMobile';
import { HiddenDesktop } from '../../../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenDesktop/HiddenDesktop';
import { DownloadDocumentButton } from './DownloadDocumentButton/DownloadDocumentButton.use-client';

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  borderColor: 'neutrals.divider',
  borderBottomWidth: '1px',
  gap: 'extra_small_2',
  paddingX: 'medium',
  paddingY: 'extra_large',
  width: '100%',

  lg: {
    paddingY: 'medium',
  },
});

const detailsStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: 'extra_small',
});

const textStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'extra_small_2',

  '& > span:first-child': {
    color: 'neutrals.text_primary',
    fontSize: 'label.medium_medium',
    lineHeight: 'label.medium_medium',
    letterSpacing: 'label.medium_medium',
    fontWeight: 'label.medium_medium',
  },

  '& > span:last-child': {
    color: 'neutrals.text_secondary',
    fontSize: 'body.medium',
    fontWeight: 'body.medium',
    letterSpacing: 'body.medium',
    lineHeight: 'body.medium',
  },
});

const statusStyles = css({
  width: 'fit-content',

  lg: {
    marginInlineStart:
      'calc(var(--spacing-large) + var(--spacing-extra_small_4))',
  },
});

export interface DocumentItemProps {
  document: {
    id: string;
    name: string;
    read: boolean;
    createdAt: string;
    url: string | ((documentId: string) => Promise<string>);
  };
  translations: {
    unread: string;
  };
}

const DocumentItem = ({ document, translations }: DocumentItemProps) => {
  const currentLocale = getLocale(i18n.resolvedLanguage as Language);

  const [isRead, setIsRead] = useState(document.read);

  const createdAtDate = convertToLocaleDateString(
    document?.createdAt,
    currentLocale,
  );

  const DocumentReadStatus = () =>
    !isRead && (
      <div className={statusStyles}>
        <Status visual="positive" text={translations.unread} />
      </div>
    );

  return (
    <div className={containerStyles} data-testid={`doc-${document?.id}`}>
      <div className={detailsStyles}>
        <HiddenMobile>
          <div className={css({ minWidth: '24px', height: '30.5px' })}>
            <Icon icon="document" theme="regular" size="24" />
          </div>
        </HiddenMobile>

        <div className={textStyles}>
          <span>{document?.name}</span>
          <span>{createdAtDate}</span>
        </div>

        <div
          className={css({
            display: 'flex',
            gap: 'small',
            alignItems: 'center',
            marginInlineStart: 'auto',
          })}
        >
          <HiddenDesktop>
            <DocumentReadStatus />
          </HiddenDesktop>

          <DownloadDocumentButton
            documentId={document.id}
            documentDownloadUrl={document.url}
            onDownloadCompleted={() => setIsRead(true)}
          />
        </div>
      </div>

      <HiddenMobile>
        <DocumentReadStatus />
      </HiddenMobile>
    </div>
  );
};

export { DocumentItem };
