import { css } from '@bts-web/utils-style-engine';
import { type TransactionsViewQuery } from '@bts-web/data-layer/server';
import { Icon } from '@bts-web/design-system/component/icon';
import { getTransactionsWidgetTranslations } from './utils/getTransactionsWidgetTranslations';
import { itemslistHeaderStyles } from '../styles';
import { getTransactions } from '../gqlActions';
import { ContentSectionWrapper } from '../../layouts/ContentSectionWrapper/ContentSectionWrapper';
import { Link } from '../components';
import { TFunction } from 'i18next';
import {
  getLocale,
  i18n,
  initServerTranslation,
  Language,
  updateServerLanguage,
} from '@bts-web/utils-lokalise';
import { TransactionList } from '../../transactions/TransactionList/TransactionList';

const { t } = initServerTranslation();

const transactionsLinkStyles = css({
  gap: 'extra_small_2',
  fontSize: 'small',
  display: 'flex',
  alignItems: 'center',
});

const descriptionStyles = css({
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
  color: 'neutrals.text_primary',
  paddingX: 'medium',
});

const DescriptionComponent = ({
  latestTransactionsDescription,
}: {
  latestTransactionsDescription: string;
}) => (
  <span
    data-testid="latest-transactions-description"
    className={descriptionStyles}
  >
    {latestTransactionsDescription}
  </span>
);

type TransactionsWidgetVariant = 'Portfolio' | 'AssetDetails';

export interface TransactionsWidgetControllerProps {
  variant: TransactionsWidgetVariant;
  showOnlyRedirectLink?: boolean;
  fetchNumberOfItems: number;
  assetId?: string;
}

export const TransactionsWidgetController = async ({
  variant,
  showOnlyRedirectLink = false,
  fetchNumberOfItems = 20,
  assetId,
}: TransactionsWidgetControllerProps) => {
  await updateServerLanguage();

  const locale = getLocale(i18n.resolvedLanguage as Language);

  const widgetTranslations = getTransactionsWidgetTranslations(t as TFunction);

  const latestTransactions = await getTransactions({
    input: {
      ...(assetId && { assetId }),
      first: fetchNumberOfItems,
    },
  });

  const transactions =
    (
      latestTransactions?.data?.transactions as NonNullable<
        TransactionsViewQuery['response']['transactions']
      >
    )?.edges ?? [];

  // do not display related transactions if the user do not own any asset in the asset-detail page
  if (assetId && !transactions?.length) {
    return null;
  }

  const transactionsLink = (
    <Link
      size="small"
      href={assetId ? `/transactions?assetId=${assetId}` : '/transactions'}
    >
      <span className={transactionsLinkStyles}>
        {assetId
          ? widgetTranslations.viewRelatedTransactions
          : widgetTranslations.viewAllTransactions}
        <Icon size="16" icon="arrow-right" />
      </span>
    </Link>
  );

  if (showOnlyRedirectLink) {
    return <ContentSectionWrapper>{transactionsLink}</ContentSectionWrapper>;
  }

  const noTransactionsToShow = !transactions || transactions?.length === 0;

  return (
    <ContentSectionWrapper
      sectionTitle={
        variant === 'AssetDetails'
          ? widgetTranslations.viewRelatedTransactions
          : widgetTranslations.latestTransactions
      }
      data-testid="transactions widget"
    >
      {noTransactionsToShow && (
        <span data-testid="empty-transactions" className={descriptionStyles}>
          {widgetTranslations.emptyStateNoTransactions}
        </span>
      )}

      {!noTransactionsToShow && (
        <div>
          <div className={itemslistHeaderStyles}>
            <span>{widgetTranslations.transactionDescription}</span>
            <span>{widgetTranslations.amount}</span>
          </div>
          <TransactionList transactions={transactions} locale={locale} />
          <div
            className={css({
              mt: 'medium',
            })}
          >
            {transactionsLink}
          </div>
        </div>
      )}

      {noTransactionsToShow && (
        <DescriptionComponent
          latestTransactionsDescription={
            widgetTranslations.latestTransactionsDescription
          }
        />
      )}
    </ContentSectionWrapper>
  );
};
