import { ReactElement, ReactNode } from 'react';

export interface TransactionCellProps {
  internal: {
    className: string;
    moneyAmountClassName: string;
    statusClassName: string;
  };
  external: {
    tagContent?: ReactNode | null;
    firstIconSlot: ReactElement;
    secondIconSlot: ReactElement | null;
    transactionBadgesSlot?: ReactElement;
    title: string;
    date: string;
    disabled?: boolean;
    type?: HTMLButtonElement['type'];
    fiatAmountText: string;
    assetAmountText: string;
    transactionStatus?: string;
    onClick?: () => void;
  };
}

const TransactionCell = ({
  className,
  moneyAmountClassName,
  statusClassName,
  firstIconSlot,
  secondIconSlot,
  transactionBadgesSlot,
  title,
  date,
  fiatAmountText,
  assetAmountText,
  disabled,
  transactionStatus,
  tagContent,
  onClick,
}: TransactionCellProps['internal'] & TransactionCellProps['external']) => {
  return (
    <div
      className={`${className}`}
      data-testid="transaction-cell"
      {...(!disabled && { 'data-state': 'active', onClick })}
    >
      <div data-element="details">
        <div data-element="details-first-half">
          <div data-element="first-icon-slot">{firstIconSlot}</div>

          <div>
            <span data-element="title">{title}</span>
            <span>{date}</span>
            {tagContent && tagContent}
          </div>
        </div>

        <div data-element="details-second-half">
          <div>
            <span className={moneyAmountClassName}>{fiatAmountText}</span>
            <span>{assetAmountText}</span>
            {transactionStatus && (
              <span
                data-testid="transaction-cell__status"
                className={statusClassName}
              >
                {transactionStatus}
              </span>
            )}
          </div>

          {secondIconSlot && (
            <div data-element="second-icon-slot">{secondIconSlot}</div>
          )}
        </div>
      </div>

      {transactionBadgesSlot && (
        <div data-element="badges">{transactionBadgesSlot}</div>
      )}
    </div>
  );
};

export { TransactionCell };
