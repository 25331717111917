'use client';

import {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';
import { DialogProps } from '@bts-web/design-system/component/dialog';

export type BaseModalProps = {
  onClose: () => void;
};

export type ModalComponent<T = object> = (
  props: BaseModalProps & T,
) => ReactElement | null;

export type DialogComponentProps = Omit<DialogProps['external'], 'closeIcon'>;

type ShowModalFunction = <T extends object = object>(
  ModalComponent: ModalComponent<T>,
  additionalProps?: Omit<T, keyof BaseModalProps>,
) => void;

interface ModalState<T extends object = object> {
  Component: ModalComponent<T> | null;
  props: Omit<T, keyof BaseModalProps> | null;
}

interface ModalContextProps {
  showModal: ShowModalFunction;
  onClose: () => void;
}

const ModalContext = createContext<ModalContextProps | null>(null);

export function useComposableModal(): ModalContextProps {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error(
      'useComposableModal must be used within a ComposableModalContextProvider',
    );
  }

  return context;
}

export const ComposableModalContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [modalState, setModalState] = useState<ModalState<any>>({
    Component: null,
    props: null,
  });

  const onClose = () => setModalState({ Component: null, props: null });

  const showModal: ShowModalFunction = (
    ModalComponent,
    additionalProps = {} as never,
  ) => {
    setModalState({ Component: ModalComponent, props: additionalProps });
  };

  const { Component, props } = modalState;

  return (
    <ModalContext.Provider value={{ showModal, onClose }}>
      {children}
      {Component && <Component {...(props as any)} onClose={onClose} />}
    </ModalContext.Provider>
  );
};
