import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { ButtonBase, DialogBase, HyperLinkButtonBase } from '../../common';
import { StartSavingTranslationsKeys } from '../types';

const container = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'small',
  height: '100%',
});

const modalContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'small',
  paddingY: 'medium',
  height: '100%',
});

const modalSectionStyles = css({
  display: 'flex',
  gap: 'small',
});

const modalSectionTextStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
  gap: 'extra_small_2',

  '& > h3': {
    color: 'neutrals.text_primary',
    fontSize: 'title.medium_medium',
    fontWeight: 'title.medium_medium',
    lineHeight: 'title.medium_medium',
    letterSpacing: 'title.medium_medium',
  },

  '& > span': {
    color: 'neutrals.text_secondary',
    fontSize: 'body.medium',
    fontWeight: 'body.medium',
    lineHeight: 'body.medium',
    letterSpacing: 'body.medium',
    textAlign: 'left',
  },
});

const buttonsContainer = css({
  marginTop: 'auto',
  display: 'flex',
  gap: 'extra_small',
});

export interface StartSavingModalProps {
  onClose: () => void;
  continueUrl: string;
  translations: StartSavingTranslationsKeys;
}

const StartSavingModal = ({
  onClose,
  continueUrl,
  translations,
}: StartSavingModalProps) => {
  return (
    <DialogBase
      title={translations.startSavingTitle}
      id="start-saving-plans"
      visual="secondary"
      size="medium"
      open
      onOpenChange={onClose}
    >
      <div className={container}>
        <div className={modalContainerStyles}>
          <section className={modalSectionStyles}>
            <Icon iconPrefix="mdi" icon="gear-outline" width={24} height={24} />
            <div className={modalSectionTextStyles}>
              <h3>{translations.startSavingSectionTitleOne}</h3>
              <span>{translations.startSavingSectionDescriptionOne}</span>
            </div>
          </section>
          <section className={modalSectionStyles}>
            <Icon icon="lightbulb" theme="regular" width={24} height={24} />
            <div className={modalSectionTextStyles}>
              <h3>{translations.startSavingSectionTitleTwo}</h3>
              <span>{translations.startSavingSectionDescriptionTwo}</span>
            </div>
          </section>
          <section className={modalSectionStyles}>
            <Icon icon="data-trending" theme="regular" width={24} height={24} />
            <div className={modalSectionTextStyles}>
              <h3>{translations.startSavingSectionTitleThree}</h3>
              <span>{translations.startSavingSectionDescriptionThree}</span>
            </div>
          </section>
        </div>
        <div className={buttonsContainer}>
          <ButtonBase
            className={css({
              flex: 1,
              maxWidth: '100%',
            })}
            visual="secondary"
            onClick={onClose}
          >
            {translations.goBack}
          </ButtonBase>
          <HyperLinkButtonBase
            className={css({ flex: 1 })}
            visual="primary"
            to={continueUrl}
            onClick={onClose}
          >
            {translations.continue}
          </HyperLinkButtonBase>
        </div>
      </div>
    </DialogBase>
  );
};

export { StartSavingModal };
