import type { TransactionsViewQuery } from '@bts-web/data-layer/server';
import { Locale } from '@bts-web/utils-lokalise';
import { TransactionItem } from '../TransactionItem/TransactionItem';

export interface TransactionListProps {
  transactions: NonNullable<
    TransactionsViewQuery['response']['transactions']
  >['edges'];
  locale: Locale;
}

export const TransactionList = ({
  transactions,
  locale,
}: TransactionListProps) => {
  return (
    <ul aria-label="transaction-list">
      {(transactions || []).map((transaction) => {
        if (transaction) {
          return (
            <TransactionItem
              key={transaction?.node?.id}
              transaction={transaction.node}
              locale={locale}
            />
          );
        }

        return null;
      })}
    </ul>
  );
};
