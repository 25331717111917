import { FC, ReactNode, Suspense } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { getAppConfig } from '@bts-web/core-app-config';
import { initServerTranslation } from '@bts-web/utils-lokalise';
import { DesktopLeftNav } from '../DesktopLeftNav/DesktopLeftNav';
import { MobileHeader } from '../MobileHeader/MobileHeader';
import { DesktopTopHeaderBar } from '../DesktopTopHeaderBar/DesktopTopHeaderBar';
import { DesktopTopHeaderBarSkeleton } from '../DesktopTopHeaderBar/DesktopTopHeaderBarSkeleton';
import { DesktopLeftNavSkeleton } from '../DesktopLeftNav/DesktopLeftNavSkeleton';
import { LayoutTitleElement, TitleUnionType } from './LayoutTitleElement';
import { HiddenDesktop } from '../Reusable/HiddenDesktop/HiddenDesktop';
import { HiddenMobile } from '../Reusable/HiddenMobile/HiddenMobile';
import { MobileNavWithTrade } from '../MobileNavWithTrade/MobileNavWithTrade';
import { DesktopDisclaimerFooter } from '../DesktopDisclaimerFooter/DesktopDisclaimerFooter';
import { TFunction } from 'i18next';
import { MobileHeaderSkeleton } from '../MobileHeader/MobileHeaderSkeleton';
import { getMainNavsTranslations } from '../utils/getMainNavsTranslations';
import { getSavingsPlansCount } from '../../../common/gqlActions/getSavingsPlansCount.action';
import { getSavingsPlansTranslations } from '../../../savings-plans/utils/getSavingsPlansTranslations';

/**
 * `AppMainLayoutWrapper` is a functional component that provides the main layout for the application.
 *
 * @component
 * @param {object} props - The properties that define the component's behavior and display.
 * @param {(initialKey: string, otherParams?: unknown) => string} props.translationUtil - A utility function for translations.
 * @param {ReactNode} [props.children] - The child components to be rendered within the layout.
 * @param {TitleUnionType} props.title - Object that defines the title of the page, it supports breadcrumbs and string
 * @param {object} [props.settings] - Additional settings for the layout.
 * @param {boolean} [props.settings.hasMobileBottomNav] - Determines if the bottom navigation should be displayed on mobile view.
 * @param {boolean} [props.settings.hideMobileHeader] - Determines if the mobile header should be hidden.
 * @param {boolean} [props.settings.noMainElementPaddingBottom] - Determines if the main element should have no padding at the bottom, this is for usage in conjunction with AssetDetailTradeWrapper
 * @param {boolean} [props.settings.hideTopBarTradeButton] - Determines if the trade button in the top bar should be hidden, this is for trade pages where the trade process is in progress
 * @param {boolean} [props.settings.hideDesktopFooter] - Determines if the desktop footer should be hidden, used in the pages where the desktop view contains both a trade element and an asset detail element
 * @param {boolean} [props.settings.fullPageBackdropMode] - Makes the layout overlay the entire page, used for modals
 * @example
 * return (
 *   <AppMainLayoutWrapper
 *     translationUtil={translate}
 *     title="Home"
 *     settings={{
 *       hasMobileBottomNav: true,
 *       hideMobileHeader: false,
 *       noMainElementPaddingBottom: true,
 *       hideTopBarTradeButton: false,
 *       hideDesktopFooter: false,
 *     }}
 *   >
 *     <Home />
 *   </AppMainLayoutWrapper>
 * );
 *
 * @returns {ReactElement} The rendered `AppMainLayoutWrapper` component.
 */
const { t: translationUtil } = initServerTranslation();

export const AppMainLayoutWrapper: FC<{
  children?: ReactNode;
  title: TitleUnionType;
  settings?: {
    hasMobileBottomNav?: boolean;
    hideMobileHeader?: boolean;
    noMainElementPaddingBottom?: boolean;
    hideTopBarTradeButton?: boolean;
    hideDesktopFooter?: boolean;
    fullPageBackdropMode?: boolean;
  };
}> = async ({ children, title, settings }) => {
  const {
    hasMobileBottomNav,
    hideMobileHeader,
    noMainElementPaddingBottom,
    hideTopBarTradeButton,
    hideDesktopFooter,
    fullPageBackdropMode,
  } = settings ?? {};

  const translations = getMainNavsTranslations(translationUtil as TFunction);

  const savingsPlansTranslations = getSavingsPlansTranslations(
    translationUtil as TFunction,
  );

  const { feature_hasSavingsPlansEnabled } = getAppConfig();

  let savingsPlansCount;

  if (feature_hasSavingsPlansEnabled && hasMobileBottomNav) {
    const savingsPlansData = await getSavingsPlansCount({
      status: 'ACTIVE',
      first: 100,
    });

    savingsPlansCount = savingsPlansData?.data?.savingsPlans?.totalCount;
  }

  return (
    <div
      className={css({
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: { base: undefined, lg: 'neutrals.widget_primary' },
        paddingInlineStart: {
          base: undefined,
          lg: `calc(var(--spacing-extra_small_4) + 73px)`,
        },
        paddingTop: {
          base: undefined,
          lg: `calc(var(--spacing-extra_small_4) + 72px)`,
        },
        height: fullPageBackdropMode ? '100vh' : undefined,
        overflow: fullPageBackdropMode ? 'hidden' : undefined,
      })}
    >
      {!hideMobileHeader && (
        <HiddenDesktop>
          <Suspense fallback={<MobileHeaderSkeleton />}>
            <MobileHeader
              translations={{
                englishSubtitle: translations.englishSubtitle,
                germanSubtitle: translations.germanSubtitle,
                errorSubtitle: translations.errorSubtitle,
                errorTitle: translations.errorTitle,
                title: translations.languageTitle,
              }}
            />
          </Suspense>
        </HiddenDesktop>
      )}

      <HiddenMobile>
        <Suspense fallback={<DesktopTopHeaderBarSkeleton />}>
          <DesktopTopHeaderBar
            hideTopBarTradeButton={hideTopBarTradeButton ?? false}
            pageTitleElement={<LayoutTitleElement title={title} />}
            translatedValues={translations}
          />
        </Suspense>
      </HiddenMobile>

      <HiddenMobile>
        <Suspense fallback={<DesktopLeftNavSkeleton />}>
          <DesktopLeftNav translatedValues={translations} />
        </Suspense>
      </HiddenMobile>

      <main
        style={{
          paddingBottom: noMainElementPaddingBottom ? 0 : undefined,
        }}
        className={css({
          display: 'flex',
          flexDirection: 'column',
          flex: 'auto',
          bgColor: 'neutrals.card_fill_primary',
          width: {
            base: '100%',
            lg: '1440px',
          },
          maxWidth: '100%',
          mx: 'auto',
          pb: {
            base: hasMobileBottomNav
              ? 'var(--bottom-tabbar-spacing)'
              : 'medium',
            lg: '0',
          },
        })}
      >
        {children}
      </main>

      {hasMobileBottomNav ? (
        <HiddenDesktop>
          <MobileNavWithTrade
            labels={{
              discover: translations.discoverText,
              portfolio: translations.portfolioText,
              trade: translations.tradeText,
              ...savingsPlansTranslations,
            }}
            savingsPlansCount={savingsPlansCount as number}
          />
        </HiddenDesktop>
      ) : null}

      {!hideDesktopFooter && (
        <Suspense fallback={null}>
          <DesktopDisclaimerFooter
            translationUtil={translationUtil as TFunction}
          />
        </Suspense>
      )}
    </div>
  );
};
