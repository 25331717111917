import { AssetTypeName } from '../../../portfolio/utils/types';
import {
  GlobalCoinLogo,
  GlobalMetalLogo,
  GlobalETFLogo,
  GlobalETCLogo,
  GlobalStockLogo,
} from '../../assets';

export const assetImageByName: Record<AssetTypeName, string> = {
  [AssetTypeName.COIN]: GlobalCoinLogo,
  [AssetTypeName.METAL]: GlobalMetalLogo,
  [AssetTypeName.ETF]: GlobalETFLogo,
  [AssetTypeName.ETC]: GlobalETCLogo,
  [AssetTypeName.STOCK]: GlobalStockLogo,
};

export enum PortfolioAssetOnClickActions {
  GO_TO_ASSET_DETAILS = 'DETAILS',
  GO_TO_MY_ASSETS = 'MY_ASSETS',
}
