'use client';

import { useRouter } from 'next/navigation';
import {
  intlAssetValueFormatting,
  getRoundedPrecision,
  assetUnitsValueToPercentage,
  intlFiatValueFormatting,
} from '@bts-web/utils-formatting';
import {
  getLocale,
  i18n,
  Language,
  Locale,
  useClientTranslation,
} from '@bts-web/utils-lokalise';
import { AssetTypeName } from '../../../portfolio/utils/types';
import PriceVariation from '../PriceVariation/PriceVariation.use-client';
import { AssetGroupEdgesType, AssetGroupsType } from '../../types';
import { AccordionProps } from '@bts-web/design-system/component/accordion';
import { Accordion } from '../Accordion/Accordion';
import { defaultAssetImg } from '../../assets';
import { assetImageByName, PortfolioAssetOnClickActions } from './utils';

export type PortfolioAssetsTranslations = {
  crypto: string;
  etfs: string;
  metals: string;
  stocks: string;
  etcs: string;
};

interface getAssetGroupContentsProps {
  locale: Locale;
  actionMode: PortfolioAssetOnClickActions;
  edges: AssetGroupEdgesType;
  router: ReturnType<typeof useRouter>;
  t: ReturnType<typeof useClientTranslation>['t'];
}

export interface PortfolioAssetsProps {
  actionMode: PortfolioAssetOnClickActions;
  assetGroups: NonNullable<AssetGroupsType>;
  translations: PortfolioAssetsTranslations;
}

const goToAssetDetailsOrSellBasedOnActionMode = (
  assetId: string,
  actionMode: PortfolioAssetOnClickActions,
  router: ReturnType<typeof useRouter>,
) => {
  if (actionMode === PortfolioAssetOnClickActions.GO_TO_ASSET_DETAILS) {
    router.push(`/asset-details/${assetId}?ref=portfolio`);
  }

  if (actionMode === PortfolioAssetOnClickActions.GO_TO_MY_ASSETS) {
    router.push(`/trade/sell/${assetId}`);
  }
};

const getAssetGroupContents = ({
  edges,
  locale,
  t,
  router,
  actionMode,
}: getAssetGroupContentsProps) =>
  edges?.map((edge) => {
    const edgeTotalReturnPercentage = Number(edge?.totalReturn?.percentage);

    if (!edge?.node?.id) {
      return {
        subtitle: t('info_toast_asset_unavailable_title'),
      };
    }

    return {
      title: edge?.node?.name,
      subtitle: t('unit_amount', {
        amount: intlAssetValueFormatting(
          getRoundedPrecision(edge?.assetBalance),
          {
            locale,
            fractionDigits: edge?.assetBalance?.precision,
          },
        ),
      }),
      image: edge?.node?.logoUrl,
      fallbackImage: defaultAssetImg,
      onClick: () =>
        goToAssetDetailsOrSellBasedOnActionMode(
          edge?.node?.id as string,
          actionMode,
          router,
        ),
      ContentIndicatorSlot: (
        <PriceVariation
          value={intlFiatValueFormatting(
            getRoundedPrecision(edge?.fiatBalance),
            {
              locale,
              currency: 'EUR',
              fractionDigits: edge?.fiatBalance?.precision,
            },
          )}
          percentage={assetUnitsValueToPercentage(edgeTotalReturnPercentage, {
            isAbsolute: true,
            locale,
          })}
          visual={
            edgeTotalReturnPercentage > 0 ? 'filledPositive' : 'filledNegative'
          }
          size="smallAccent"
          trend={edgeTotalReturnPercentage > 0 ? 'positive' : 'negative'}
          valuePosition="bottom"
        />
      ),
    };
  });

const PortfolioAssets = ({
  actionMode,
  assetGroups,
  translations,
}: PortfolioAssetsProps) => {
  const router = useRouter();

  const { t } = useClientTranslation();

  const locale = getLocale(i18n.resolvedLanguage as Language);

  const assetTitleByName: Record<AssetTypeName, string> = {
    [AssetTypeName.COIN]: translations.crypto,
    [AssetTypeName.METAL]: translations.metals,
    [AssetTypeName.STOCK]: translations.stocks,
    [AssetTypeName.ETF]: translations.etfs,
    [AssetTypeName.ETC]: translations.etcs,
  };

  const accordionItems: AccordionProps['external']['items'] = [...assetGroups]
    .sort((itemA, itemB) => Number(itemA.order) - Number(itemB.order))
    .map((assetGroup) => {
      const totalReturnPercentage = Number(assetGroup.totalReturn.percentage);

      const firstAssetGroup = assetGroup?.node?.[0];

      return {
        value: assetGroup.name,
        header: {
          title: assetTitleByName[assetGroup.name as AssetTypeName],
          subtitle: t('dashboard_portfolio_asset_amount_plural', {
            0: firstAssetGroup?.totalCount || 0,
          }),
          image: assetImageByName[assetGroup.name as AssetTypeName],
          ContentIndicatorSlot: (
            <PriceVariation
              value={intlFiatValueFormatting(
                getRoundedPrecision(assetGroup.fiatBalance),
                {
                  locale,
                  currency: 'EUR',
                  fractionDigits: assetGroup.fiatBalance?.precision,
                },
              )}
              percentage={assetUnitsValueToPercentage(totalReturnPercentage, {
                isAbsolute: true,
                locale,
              })}
              visual={
                totalReturnPercentage > 0 ? 'filledPositive' : 'filledNegative'
              }
              size="smallAccent"
              trend={totalReturnPercentage > 0 ? 'positive' : 'negative'}
              valuePosition="bottom"
            />
          ),
        },
        contents: getAssetGroupContents({
          edges: firstAssetGroup?.edges || [],
          locale,
          t,
          router,
          actionMode,
        }),
      };
    }) as AccordionProps['external']['items'];

  return <Accordion items={accordionItems} />;
};

export { PortfolioAssets };
