import { FC, ReactNode, Suspense } from 'react';
import { ErrorBoundary } from './RootErrorBoundary';
import { css } from '@bts-web/utils-style-engine';
import { ErrorRetryButton } from './ErrorRetryButton';

const ErrorBoundaryWithSuspense: FC<{
  fallbackOrSkeleton: ReactNode;
  children: ReactNode;
}> = ({ fallbackOrSkeleton, children }) => {
  return (
    <ErrorBoundary
      fallback={
        <div
          className={css({
            position: 'relative',
          })}
        >
          {fallbackOrSkeleton}
          <ErrorRetryButton
            className={css({
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 'auto',
            })}
          />
        </div>
      }
    >
      <Suspense fallback={fallbackOrSkeleton}>{children}</Suspense>
    </ErrorBoundary>
  );
};

export { ErrorBoundaryWithSuspense };
