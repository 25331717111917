import { css } from '@bts-web/utils-style-engine';

export const itemslistHeaderStyles = css({
  fontSize: 'caption.medium_medium',
  fontWeight: 'caption.medium_medium',
  letterSpacing: 'caption.medium_medium',
  lineHeight: 'caption.medium_medium',
  color: 'neutrals.text_secondary',
  display: 'flex',
  justifyContent: 'space-between',
  py: 'extra_small',
  hideBelow: 'lg',
});
