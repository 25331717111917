import { FC } from 'react';
import {
  Accordion as DesignAccordion,
  type AccordionProps as DesignSystemAccordionProps,
} from '@bts-web/design-system/component/accordion';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';

const Accordion: FC<DesignSystemAccordionProps['external']> = (props) => {
  return (
    <DesignAccordion
      {...props}
      IconComponent={<Icon icon="arrow-down-primary" size="16" />}
      className={css({
        '& [data-element="content-container"]': {
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          flex: 'auto',
          borderBottomWidth: '1px',
          borderColor: 'neutrals.divider',
          '&:last-of-type': {
            border: 'none',
          },
        },
        '& [data-element="content-wrap"]': {
          display: 'flex',
          alignItems: 'center',
          gap: 'small',
          padding: 'small',
          paddingInlineStart: 0,
        },
        '& [data-element="content-slot"]': {
          padding: 'small',
        },
        '& [data-element="content-header"]': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: 'extra_small_3',
          bg: 'neutrals.card_fill_primary',
        },
        '& [data-element="content-title"]': {
          color: 'neutrals.text_primary',
          fontSize: 'label.medium_medium',
          fontWeight: 'label.medium_medium',
          lineHeight: 'label.medium_medium',
          letterSpacing: 'label.medium_medium',
        },
        '& [data-element="header"] [data-element="content-title"]': {
          fontSize: 'label.medium_bold',
          fontWeight: 'label.medium_bold',
          lineHeight: 'label.medium_bold',
          letterSpacing: 'label.medium_bold',
        },
        '& [data-element="content-subtitle"]': {
          fontSize: 'caption.medium_medium',
          fontWeight: 'caption.medium_medium',
          lineHeight: 'caption.medium_medium',
          letterSpacing: 'caption.medium_medium',
          color: 'neutrals.text_secondary',
        },
        '& [data-element="content"]': {
          animation: `slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
          paddingRight: 'small',
        },
        '& [data-element="trigger"]': {
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          '& [data-element="content-slot"]': {},
          '& > [data-element="icon"]': {
            display: 'flex',
            px: '0',
            py: 'medium',
            transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
          },
          '&[data-state="open"] > [data-element="icon"]': {
            transform: 'rotate(180deg)',
          },
        },
      })}
      itemClassName={css({
        backgroundColor: 'screen_background.primary',
        maxHeight: 400,
        overflowY: 'auto',
        overflowX: 'hidden',
        '&[data-state="closed"]:not(:last-child) [data-element="header"]': {
          borderBottomWidth: '1px',
          borderColor: 'neutrals.divider',
        },
        '&[data-state="open"]': {
          position: 'sticky',
          top: 0,
          zIndex: 2,
        },
        '&[data-state="open"] [data-element="header"]': {
          bg: 'neutrals.card_fill_primary',
          position: 'sticky',
          top: 0,
          zIndex: 2,
        },
        '&[data-state="open"] [data-element="content"]': {
          overflow: 'hidden',
          backgroundColor: 'screen_background.primary',
        },
        '&[data-state="closed"] > [data-element="content"]': {
          animation: `slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
        },
      })}
    />
  );
};

export { Accordion };
