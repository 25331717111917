import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';

export type ChipVisualVariants =
  | 'primary'
  | 'secondary'
  | 'ghost'
  | 'accent'
  | 'primaryOnBrand';

export type TChipProps = {
  label: string;
  color?: string;
  onClick?: () => void;
  className?: string;
  visual?: ChipVisualVariants;
};

const Chip: FC<TChipProps> = ({
  label,
  className,
  color,
  onClick,
  visual = 'primary',
}) => {
  return (
    <div
      className={`${className} ${css({
        maxWidth: '100%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        height: 'medium',
        verticalAlign: 'middle',
        outline: 0,
        borderRadius: '16px',
        border: '1px solid',
        lineHeight: 1,
        ...(visual === 'primary'
          ? {
              borderColor: 'neutrals.stroke_secondary',
              backgroundColor: 'neutrals.stroke_secondary',
              color: 'neutrals.text_primary',
              '&:active:enabled': {
                backgroundColor: 'neutrals.fill_pressed',
                color: 'neutrals.text_primary',
              },
              '&:focus:enabled': {
                backgroundColor: 'neutrals.fill_pressed',
                color: 'neutrals.text_primary',
              },
              '&:disabled': {
                backgroundColor: 'neutrals.fill_disabled',
                color: 'neutrals.text_disabled',
              },
            }
          : {}),
        ...(visual === 'secondary'
          ? {
              borderWidth: '1px',
              borderColor: 'neutrals.stroke_primary',
              backgroundColor: 'neutrals.card_fill_primary',
              color: 'neutrals.text_primary',
              '&:active:enabled': {
                backgroundColor: 'neutrals.card_fill_primary',
                color: 'neutrals.text_primary',
                borderColor: 'neutrals.stroke_primary',
              },
              '&:focus:enabled': {
                backgroundColor: 'neutrals.fill_focused',
                color: 'neutrals.text_primary',
                borderColor: 'neutrals.stroke_primary',
              },
              '&:disabled': {
                backgroundColor: 'neutrals.card_fill_primary',
                color: 'neutrals.text_disabled',
                borderColor: 'neutrals.stroke_primary_disabled',
              },
            }
          : {}),
        ...(visual === 'ghost'
          ? {
              color: 'neutrals.text_primary',
              '&:active:enabled': {
                color: 'neutrals.text_primary',
              },
              '&:focus:enabled': {
                color: 'neutrals.text_primary',
              },
              '&:disabled': {
                color: 'neutrals.text_disabled',
              },
            }
          : {}),
        ...(visual === 'accent'
          ? {
              backgroundColor: 'brand.fill_primary',
              color: 'brand.on_fill_primary',
              '&:active:enabled': {
                backgroundColor: 'brand.fill_pressed',
                color: 'brand.on_fill_primary',
              },
              '&:focus:enabled': {
                backgroundColor: 'brand.fill_pressed',
                color: 'brand.on_fill_primary',
              },
              '&:disabled': {
                backgroundColor: 'neutrals.fill_disabled',
                color: 'neutrals.text_disabled',
              },
              '&:hover:not(:disabled)': {
                backgroundColor: 'neutrals.on_fill_dark_disabled',
              },
            }
          : {}),
        ...(visual === 'primaryOnBrand'
          ? {
              backgroundColor: 'neutrals.on_fill_dark',
              color: 'neutrals.on_fill_light',
              '&:active:enabled': {
                backgroundColor: 'neutrals.on_fill_dark',
                color: 'neutrals.on_fill_light',
              },
              '&:focus:enabled': {
                backgroundColor: 'neutrals.fill_focused',
                color: 'neutrals.on_fill_light',
              },
              '&:disabled': {
                backgroundColor: 'neutrals.on_fill_dark_disabled',
                color: 'neutrals.text_disabled',
              },
            }
          : {}),
      })}`}
    >
      <span
        className={css({
          px: 'small',
          py: 'extra_small_4',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          fontSize: 'caption.small',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        {label}
      </span>
    </div>
  );
};

export { Chip };
