import {
  Status as DesignStatus,
  StatusProps,
} from '@bts-web/design-system/component/status';
import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';

type ThemedStatusProps = {
  visual?: 'filledNeutralLight' | 'filledNeutralDark' | 'positive' | 'warning';
  size?: 'medium' | 'small';
} & StatusProps;

const Status: FC<ThemedStatusProps> = ({
  visual = 'filledNeutralLight',
  text,
  size = 'medium',
}) => {
  return (
    <DesignStatus
      text={text}
      className={css({
        borderRadius: '32px',
        display: 'inline-block',
        ...(visual === 'filledNeutralDark'
          ? {
              bg: 'neutrals.fill_primary',
              color: 'neutrals.text_primary_inverted',
            }
          : {}),
        ...(visual === 'filledNeutralLight'
          ? {
              bg: 'neutrals.fill_quinary',
              color: 'neutrals.text_primary',
            }
          : {}),
        ...(visual === 'positive'
          ? {
              borderWidth: '1px',
              borderColor: 'positive.stroke_primary',
              color: 'positive.text_primary',
            }
          : {}),
        ...(visual === 'warning'
          ? {
              borderWidth: '1px',
              borderColor: 'warning.stroke_primary',
              color: 'warning.text_primary',
            }
          : {}),
        ...(size === 'medium'
          ? {
              paddingTop: 'extra_small_3',
              paddingInlineEnd: 'extra_small_2',
              paddingBottom: 'extra_small_3',
              paddingInlineStart: 'extra_small_2',
              fontSize: 'caption.medium_medium',
              fontWeight: 'caption.medium_medium',
              lineHeight: 'caption.medium_medium',
              letterSpacing: 'caption.medium_medium',
            }
          : {}),
        ...(size === 'small'
          ? {
              paddingTop: 'extra_small_4',
              paddingInlineEnd: 'extra_small_3',
              paddingBottom: 'extra_small_4',
              paddingInlineStart: 'extra_small_3',
              fontSize: 'caption.small',
              fontWeight: 'caption.small',
              lineHeight: 'caption.small',
              letterSpacing: 'caption.small',
            }
          : {}),
      })}
    />
  );
};

export { Status };
