/**
 * @generated SignedSource<<7dbb2c98e4055d6c21dcc6563515e199>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CryptoAddressDestinationTagType = "MEMO_ID" | "MEMO_TEXT";
export type CryptoWithdrawalStatus = "CANCELED" | "FINISHED" | "PENDING" | "PROCESSING";
export type CreateCryptoWithdrawalInput = {
  assetAmount: string;
  externalAddressID: string;
};
export type CreateCryptoWithdrawalOfferMutation$variables = {
  input?: CreateCryptoWithdrawalInput | null;
};
export type CreateCryptoWithdrawalOfferMutation$data = {
  readonly createCryptoWithdrawal: {
    readonly address: {
      readonly address: string;
      readonly createdAt: any;
      readonly destinationTag: string | null;
      readonly destinationTagType: CryptoAddressDestinationTagType | null;
      readonly id: string;
    } | null;
    readonly assetAmount: PrecisionFloat | null;
    readonly assetFee: PrecisionFloat | null;
    readonly fiatAmount: PrecisionFloat | null;
    readonly fiatAmountFee: PrecisionFloat | null;
    readonly id: string;
    readonly status: CryptoWithdrawalStatus | null;
    readonly time: any;
    readonly transactionHash: string | null;
  } | null;
};
export type CreateCryptoWithdrawalOfferMutation = {
  response: CreateCryptoWithdrawalOfferMutation$data;
  variables: CreateCryptoWithdrawalOfferMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CryptoWithdrawal",
    "kind": "LinkedField",
    "name": "createCryptoWithdrawal",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "CryptoAddress",
        "kind": "LinkedField",
        "name": "address",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "destinationTag",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "destinationTagType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "assetAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "assetFee",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fiatAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fiatAmountFee",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "transactionHash",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "time",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCryptoWithdrawalOfferMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCryptoWithdrawalOfferMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9f9785190b7f0480207c6e5a920beb2e",
    "id": null,
    "metadata": {},
    "name": "CreateCryptoWithdrawalOfferMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCryptoWithdrawalOfferMutation(\n  $input: CreateCryptoWithdrawalInput\n) {\n  createCryptoWithdrawal(input: $input) {\n    id\n    address {\n      id\n      address\n      destinationTag\n      destinationTagType\n      createdAt\n    }\n    assetAmount\n    assetFee\n    fiatAmount\n    fiatAmountFee\n    status\n    transactionHash\n    time\n  }\n}\n"
  }
};
})();

(node as any).hash = "1dafb47518c51c55d5e6f34542467527";

export default node;
