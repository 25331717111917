'use client';

import { TransactionsViewQuery } from '@bts-web/data-layer/server';
import Link from 'next/link';
import { css } from '@bts-web/utils-style-engine';
import { Locale } from '@bts-web/utils-lokalise';
import {
  formatAssetAmount,
  formatFiatAmount,
  getAssetAmountText,
  getTransactionTime,
} from './transactionItemUtils';
import { TransactionCell } from '../TransactionCell/TransactionCell.use-client';
import { useTransactionTitle } from '../utils/getTransactionTitle';
import {
  transactionTypeByTypeName,
  TransactionTypes,
  transactionTypesWithDetail,
} from '../types/transactions';
import { Chip } from '../../common/components/Chip/Chip';
import { useMemo } from 'react';

export interface TransactionItemProps {
  transaction: NonNullable<
    NonNullable<
      NonNullable<TransactionsViewQuery['response']['transactions']>['edges']
    >[number]
  >['node'];
  locale: Locale;
}

export const TransactionItem = ({
  transaction,
  locale,
}: TransactionItemProps) => {
  const {
    assetAmount,
    disableDetailView,
    isOutgoing,
    transactionType,
    WrapperElement,
    date,
    fiatAmountText,
    wrapperElementProps,
  } = useMemo(() => {
    const transactionType: TransactionTypes =
      transactionTypeByTypeName[
        transaction?.__typename as keyof typeof transactionTypeByTypeName
      ];

    const isBuyOrSavingsPlan =
      transactionType === 'BUY' || transactionType === 'SAVINGS_PLAN';

    const isOutgoing = transaction?.direction === 'OUTGOING';

    const disableDetailView =
      !transactionTypesWithDetail.includes(transactionType);

    const assetAmount = formatAssetAmount(transaction?.assetAmount, locale);

    const fiatAmountText = formatFiatAmount(
      transaction?.fiatAmount,
      locale,
      isBuyOrSavingsPlan,
      disableDetailView,
      isOutgoing,
    );

    const date = getTransactionTime({
      locale,
      time: transaction?.time as string,
    });

    const WrapperElement = disableDetailView ? 'li' : Link;

    const wrapperElementProps = disableDetailView
      ? {}
      : { href: `/transactions/${transaction?.id}` };

    return {
      wrapperElementProps,
      WrapperElement,
      date,
      fiatAmountText,
      assetAmount,
      isOutgoing,
      disableDetailView,
      isBuyOrSavingsPlan,
      transactionType,
    };
  }, [
    locale,
    transaction?.__typename,
    transaction?.assetAmount,
    transaction?.direction,
    transaction?.fiatAmount,
    transaction?.id,
    transaction?.time,
  ]);

  const {
    grams: amountLabel,
    title,
    tagValue,
  } = useTransactionTitle({
    transactionType,
    name: transaction?.asset?.name,
  });

  const assetAmountText =
    transaction?.asset && transaction.asset.symbol
      ? getAssetAmountText(
          assetAmount,
          amountLabel,
          transaction.asset.__typename,
          transaction.asset.symbol,
        )
      : '';

  return (
    <WrapperElement
      href={`/transactions/${transaction?.id}`}
      data-testid="transaction-cell-link"
      aria-label="transaction cell link"
      className={css({ display: 'block' })}
      {...wrapperElementProps}
    >
      <TransactionCell
        aria-label={`transaction ${transaction?.id}`}
        displaySecondIconSlot={!disableDetailView}
        disabled={disableDetailView}
        key={transaction?.id as string}
        title={title}
        transactionType={transactionType}
        date={date}
        fiatAmountText={fiatAmountText}
        assetAmountText={assetAmountText}
        direction={isOutgoing ? 'OUTGOING' : 'INCOMING'}
        tagContent={
          tagValue ? (
            <Chip
              visual="primary"
              className={css({ marginInlineEnd: 'auto' })}
              label={tagValue}
            />
          ) : null
        }
      />
    </WrapperElement>
  );
};
