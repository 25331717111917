'use client';

import { ModalComponent } from '@bts-web/utils-context';
import { ReactNode, useEffect } from 'react';
import { Icon } from '@bts-web/design-system/component/icon';
import { usePathname, useRouter } from 'next/navigation';
import { css, stack } from '@bts-web/utils-style-engine';
import { AssetImage, DialogBase, HyperLinkButtonBase } from '../../../common';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { HyperLinkButtonBaseVariants } from '../../../common';

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  mx: 'auto',
  gap: 'extra_large',
  flex: {
    base: 'auto',
    ['lg' as string]: '1',
  },
});

const statusAndDescriptionContainerStyles = css({
  margin: 'auto 0',
  gap: 'extra_large',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  pt: {
    base: '0',
    lg: 'small',
  },
  pb: {
    base: '0',
    lg: 'medium',
  },
});

export type SuccessModalProps = {
  title: string;
  subtitle?: string;
  description?: string | ReactNode;
  poweredByLabel?: string;
  logo?: {
    url: string;
    name: string;
  };
  icon?: string;
  continueLink?: {
    label: string;
    url: string;
  };
  cancelLink?: {
    label: string;
    url: string;
  };
  goToPortfolioBtnVariant?: HyperLinkButtonBaseVariants;
};

export const SuccessModal: ModalComponent<SuccessModalProps> = ({
  logo,
  title,
  subtitle,
  description,
  poweredByLabel,
  onClose,
  icon,
  continueLink,
  cancelLink,
  goToPortfolioBtnVariant,
}) => {
  const pathName = usePathname();

  const router = useRouter();

  const { t } = useClientTranslation();

  const goToPortfolioText = cancelLink?.label || t('go_to_portfolio');

  const portfolioUrl = cancelLink?.url || '/assets/portfolio';

  const handleClose = () => {
    onClose();

    router.refresh();
  };

  useEffect(() => {
    if ([portfolioUrl, continueLink?.url].includes(pathName)) {
      onClose();
    }
  }, [continueLink?.url, portfolioUrl, onClose, pathName]);

  return (
    <DialogBase
      id="transaction-success-modal"
      open
      onOpenChange={handleClose}
      size="medium"
      onMobileFullScreen
      visual="secondary"
      disableBackdropClose
      closeIcon={null}
    >
      <div className={containerStyles}>
        <div className={statusAndDescriptionContainerStyles}>
          {icon && (
            <div
              className={css({
                width: '52px',
                height: '52px',
                color: 'positive.text_primary',
                backgroundColor: 'positive.fill_secondary',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
              })}
            >
              <Icon icon={icon} width="36" height="36" theme="regular" />
            </div>
          )}

          {logo && (
            <div
              className={css({
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '100%',
                p: {
                  base: 'extra_small_2',
                  lg: 'small',
                },
                backgroundColor: 'neutrals.fill_quinary',
                boxShadow: `0px 0px 18px 0px rgba(0, 0, 0, 0.25)`,
              })}
            >
              <AssetImage
                src={logo.url}
                width={48}
                height={48}
                alt={logo.name}
                priority
                testId="trade-asset-logo"
                aria-label="asset logo"
              />
            </div>
          )}

          <div className={stack({ gap: 'small' })}>
            <h1
              className={css({
                fontSize: 'headline.large_semi_bold',
                fontWeight: 'headline.large_semi_bold',
                lineHeight: 'headline.large_semi_bold',
                letterSpacing: 'headline.large_semi_bold',
                textAlign: 'center',
                mx: 'auto',
              })}
              data-testid="finish-trade-main-message"
            >
              {title}
            </h1>

            {subtitle && (
              <div
                className={css({
                  textAlign: 'center',
                  color: 'neutrals.text_primary',
                  fontSize: 'headline.small',
                  fontWeight: 'headline.small',
                  letterSpacing: 'headline.small',
                  lineHeight: 'headline.small',
                })}
              >
                {subtitle}
              </div>
            )}
          </div>

          <div
            className={css({
              color: 'neutrals.text_secondary',
              fontSize: 'body.medium',
              fontWeight: 'body.medium',
              letterSpacing: 'body.medium',
              lineHeight: 'body.medium',
            })}
          >
            {description}
          </div>
        </div>

        <div
          className={stack({
            marginTop: 'auto',
            marginInlineStart: 'auto',
            marginInlineEnd: 'auto',
            maxWidth: '390px',
            width: '100%',
            gap: 'small',
            alignItems: 'center',
          })}
        >
          {poweredByLabel && (
            <p
              className={css({
                color: 'neutrals.text_secondary',
                fontSize: 'caption.small',
                fontWeight: 'caption.small',
                lineHeight: 'caption.small',
                letterSpacing: 'caption.small',
                textAlign: 'center',
              })}
            >
              {poweredByLabel}
            </p>
          )}

          {continueLink?.url && (
            <HyperLinkButtonBase
              to={continueLink.url}
              visual="accent"
              prefetch
              data-testid="continue button"
            >
              {continueLink.label}
            </HyperLinkButtonBase>
          )}

          <HyperLinkButtonBase
            to={portfolioUrl}
            visual={goToPortfolioBtnVariant || 'secondary'}
            prefetch
            date-testid="goToPortfolioButton"
          >
            {goToPortfolioText}
          </HyperLinkButtonBase>
        </div>
      </div>
    </DialogBase>
  );
};
